import React, { useCallback, useEffect, useMemo } from 'react'
import { Box, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { type RootState, type ThunkDispatchProp } from 'store'
import { connect } from 'react-redux'
import {
  type CurrentUser,
  fetchCurrentUser,
  fetchLanguages,
} from 'App/app-state'
import { renderIf } from 'common/utils/render-utils'
import Sites from 'App/Sites'
import Profiles from 'App/Profiles'
import { resetLogin } from 'App/Login/login-state'
import Dashboard from 'App/Dashboard'
import Customers from 'App/Customers'
import Distributors from 'App/Distributors'
import ProfilesView from 'App/Profiles/ProfilesView'
import Networks from 'App/Networks'
import StockTablets from 'App/StockTablets'
import Tablets from 'App/Tablets'
import Docks from 'App/Docks'
import Posts from 'App/Posts'
import SiteSettings from 'App/SiteSettings'
import Codes from 'App/Codes/Codes2'
import Loans from 'App/Loans'
import Users from 'App/Users'
import TopApps from 'App/TopApps'
import Invitations from 'App/Invitations'
import Integrations from 'App/Integrations'
import CustomerSettings from 'App/CustomerSettings'
import DistributorSettings from 'App/DistributorSettings'
import Translations from 'App/Translations'
import MyAccount from 'App/MyAccount'
import IntegrationStatistics from 'App/IntegrationStatistics'
import useQuery from 'common/hooks/useQuery'
import Zendesk from 'App/Zendesk'
import SuperAdmin from 'App/SuperAdmin'
import AwsCognitoPreReleaseEmail from 'App/SuperAdmin/AwsCognitoPreReleaseEmail'
import Login from 'App/Login/components/EmailPasswordLogin'
import SSOLogin from 'App/Login/components/SSOLogin'
import DataReview from 'App/DataReview'
import AwsCognitoUserImport from 'App/SuperAdmin/AwsCognitoUserImport'
import AwsCognitoUpdatePasswordTipsEmail from 'App/SuperAdmin/AwsCognitoUpdatePasswordTips'
import DataReviewOrganizations from 'App/SuperAdmin/DataReviewOrganizations'
import DataReviewSites from 'App/SuperAdmin/DataReviewSites'
import Products from 'App/SuperAdmin/Productions'
import DataReviewDocks from 'App/SuperAdmin/DataReviewDocks'
import DataReviewTablets from 'App/SuperAdmin/DataReviewTablets'
import PasswordRecovery from 'App/Login/components/PasswordRecovery'
import ForceChangePassword from 'App/Login/components/ForceChangePassword'
import { PATHS } from 'common/constants'
import { AbilityContext } from 'common/contexts/AbilityContext'
import { defineAbilityFor, defineGuestAbility } from 'common/utils/privileges'
import { useCurrentUserContext } from 'common/hooks/useCurrentUserContext'
import Analytics from 'App/Analytics'

const useStyles = makeStyles()((theme: Theme) => ({
  App: {
    color: '#000',
    '& h1': {
      color: theme.palette.primary.main,
      margin: 0,
    },
  },
}))

interface AppProps extends ThunkDispatchProp {
  currentUser?: CurrentUser
  fetchedCurrentUser: boolean
  fetchCurrentUserError: boolean
}

const App = ({
  dispatch,
  currentUser,
  fetchedCurrentUser,
  fetchCurrentUserError,
}: AppProps) => {
  const { classes } = useStyles()
  const query = useQuery()
  const location = useLocation()

  const userContext = useCurrentUserContext()

  const userAbility = useMemo(() => {
    const ability =
      currentUser == null ? defineGuestAbility() : defineAbilityFor(currentUser)
    return ability
  }, [currentUser])

  useEffect(() => {
    dispatch(fetchLanguages())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchCurrentUser(userContext))
  }, [dispatch, userContext])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (
        location.pathname.includes(PATHS.login) ||
        location.pathname.includes(PATHS.sso) ||
        location.pathname.includes(PATHS.forgotPassword)
      ) {
        window.zE?.hide?.()
      } else {
        window.zE?.show?.()
      }
    }, 3000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [location.pathname])

  const requireLogin = useCallback(
    (element: React.ReactNode) => {
      if (currentUser == null) {
        window.zE?.('webWidget', 'logout')
        const from = query.get('from')
        if (from) {
          return <Navigate to={`${PATHS.login}?from=${from}`} />
        } else {
          return <Navigate to={PATHS.login} />
        }
      } else {
        window.zE?.('webWidget', 'prefill', {
          name: {
            value: [currentUser.firstName, currentUser.lastName].join(' '),
          },
          email: {
            value: currentUser.login,
          },
        })
        return element
      }
    },
    [currentUser, query]
  )

  const returnToParam = query.get('returnTo')
  const returnTo = useMemo(() => {
    if (
      typeof returnToParam !== 'string' ||
      !returnToParam.startsWith('/ui/')
    ) {
      return '/'
    } else {
      // React router doesn't want `/ui/`
      return returnToParam.replace(/^\/ui\//, '/')
    }
  }, [returnToParam])

  if (fetchCurrentUserError) {
    dispatch(resetLogin())
  }

  return (
    <AbilityContext.Provider value={userAbility}>
      <Box className={classes.App}>
        {renderIf(fetchedCurrentUser, () => (
          <Routes>
            <Route path="/invitations/:token" element={<Invitations />} />

            <Route
              path={PATHS.login}
              element={
                currentUser != null ? <Navigate to={returnTo} /> : <Login />
              }
            />

            <Route
              path={PATHS.sso}
              element={
                currentUser != null ? <Navigate to={returnTo} /> : <SSOLogin />
              }
            />

            <Route
              path={PATHS.root}
              element={requireLogin(<Navigate to="/dashboard" />)}
            />
            <Route path="/dashboard" element={requireLogin(<Dashboard />)} />
            <Route
              path="/distributors"
              element={requireLogin(<Distributors />)}
            />
            <Route path="/customers" element={requireLogin(<Customers />)} />
            <Route path="/sites" element={requireLogin(<Sites />)} />
            <Route
              path="/site-settings"
              element={requireLogin(<SiteSettings />)}
            />
            <Route
              path="/distributor-settings"
              element={requireLogin(<DistributorSettings />)}
            />
            <Route
              path="/customer-settings"
              element={requireLogin(<CustomerSettings />)}
            />
            <Route
              path="/profiles/:id"
              element={requireLogin(<ProfilesView />)}
            />
            <Route path="/profiles" element={requireLogin(<Profiles />)} />
            <Route path="/loans" element={requireLogin(<Loans />)} />
            <Route path="/codes" element={requireLogin(<Codes />)} />
            <Route path="/networks" element={requireLogin(<Networks />)} />
            <Route
              path="/stock-tablets"
              element={requireLogin(<StockTablets />)}
            />
            <Route path="/tablets" element={requireLogin(<Tablets />)} />
            <Route path="/docks" element={requireLogin(<Docks />)} />
            <Route path="/posts" element={requireLogin(<Posts />)} />
            <Route path="/users" element={requireLogin(<Users />)} />
            <Route
              path="/translations"
              element={requireLogin(<Translations />)}
            />
            <Route path="/top-apps" element={requireLogin(<TopApps />)} />
            <Route
              path="/integrations"
              element={requireLogin(<Integrations />)}
            />
            <Route
              path="/integration-statistics"
              element={requireLogin(<IntegrationStatistics />)}
            />
            <Route
              path="/manufacturer/docks"
              element={requireLogin(<Docks context="manufacturer" />)}
            />
            <Route path="/account" element={requireLogin(<MyAccount />)} />
            <Route path="/admin" element={requireLogin(<SuperAdmin />)} />
            <Route
              path="/admin/aws-cognito/pre-release"
              element={requireLogin(<AwsCognitoPreReleaseEmail />)}
            />
            <Route
              path="/admin/aws-cognito/user-import"
              element={requireLogin(<AwsCognitoUserImport />)}
            />
            <Route
              path="/admin/aws-cognito/update-password-tips"
              element={requireLogin(<AwsCognitoUpdatePasswordTipsEmail />)}
            />
            <Route
              path="/admin/data-review/organizations"
              element={requireLogin(<DataReviewOrganizations />)}
            />
            <Route
              path="/admin/data-review/sites"
              element={requireLogin(<DataReviewSites />)}
            />
            <Route
              path="/admin/data-review/docks"
              element={requireLogin(<DataReviewDocks />)}
            />
            <Route
              path="/admin/data-review/tablets"
              element={requireLogin(<DataReviewTablets />)}
            />
            <Route
              path="/admin/products"
              element={requireLogin(<Products />)}
            />
            <Route path={PATHS.forgotPassword} element={<PasswordRecovery />} />
            <Route
              path={PATHS.forceChangePassword}
              element={<ForceChangePassword />}
            />
            <Route
              path={PATHS.analytics}
              element={requireLogin(<Analytics />)}
            />
            <Route path="/zendesk" element={requireLogin(<Zendesk />)} />
            <Route path="/data-review" element={requireLogin(<DataReview />)} />
            <Route path="*" element={<Navigate to={PATHS.login} />} />
          </Routes>
        ))}
      </Box>
    </AbilityContext.Provider>
  )
}

const mapStateToProps = (state: RootState) => ({
  currentUser: state.app.currentUser,
  fetchedCurrentUser: state.app.fetchedCurrentUser,
  fetchCurrentUserError: state.app.fetchCurrentUserError,
})

export default connect(mapStateToProps)(App)
