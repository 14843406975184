import React from 'react'
import { type SvgIconComponent } from '@mui/icons-material'
import { Box, ListItem, styled } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useAppSelector } from 'store'

interface PageSideMenuItemProps {
  item: MenuItem
}

export interface MenuItem {
  title: string
  Icon: SvgIconComponent
  link: string
  visible: boolean
  isExternal?: boolean
}

const StyledNavLink = styled(NavLink)(({ dir, theme }) => ({
  display: 'block',
  width: '100%',
  padding: '4px 32px 4px 28px',
  lineHeight: '1',
  textDecoration: 'none',
  fontSize: '16px',
  fontWeight: 'bold',
  alignItems: 'center',
  color: '#000',
  textTransform: 'capitalize',

  textAlign: dir === 'rtl' ? 'right' : 'left',

  borderLeft: dir === 'ltr' ? '4px solid transparent' : 'none',
  borderRight: dir === 'rtl' ? '4px solid transparent' : 'none',

  '&:hover': {
    borderLeftColor: dir === 'ltr' ? theme.palette.primary.main : 'transparent',
    borderRightColor:
      dir === 'rtl' ? theme.palette.primary.main : 'transparent',
  },

  '&.active': {
    borderLeftColor: dir === 'ltr' ? theme.palette.primary.main : 'transparent',
    borderRightColor:
      dir === 'rtl' ? theme.palette.primary.main : 'transparent',
  },
}))

const PageSideMenuItem = ({ item }: PageSideMenuItemProps) => {
  const { t } = useTranslation()
  const languageDirection = useAppSelector(
    (state) => state.app.languageDirection
  )
  const { link, title, Icon, visible, isExternal } = item

  if (!visible) return null

  return (
    <ListItem sx={{ padding: '12px 0' }}>
      <StyledNavLink
        to={link}
        target={isExternal ? '_blank' : ''}
        rel={isExternal ? 'noreferrer' : ''}
        dir={languageDirection}
      >
        <Icon
          sx={{
            display: 'inline-block',
            marginLeft: languageDirection === 'ltr' ? '-3px' : '1rem',
            marginRight: languageDirection === 'ltr' ? '1rem' : '-3px',
            verticalAlign: 'middle',
            fontSize: '24px',
            color: '#000',
          }}
        />
        <Box sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
          {t(title)}
        </Box>
        {isExternal ? (
          <LaunchIcon
            sx={{
              ...{
                float: languageDirection === 'rtl' ? 'left' : 'right',
                fontSize: '14px',
                marginTop: '5px',
              },
              ...(languageDirection === 'ltr'
                ? { marginLeft: '4px' }
                : { marginRight: '4px' }),
            }}
          />
        ) : null}
      </StyledNavLink>
    </ListItem>
  )
}

export default PageSideMenuItem
