export const API_BASE_URL = '/api/v2/web'

export const PATHS = {
  /**
   * public paths
   */
  root: '/',
  sso: '/sso',
  login: '/login',
  forgotPassword: '/forgot-password',
  forceChangePassword: '/force-change-password',

  /**
   * paths for SiteAdmin and above
   */
  analytics: '/analytics',
}
