import React, { useContext, useEffect, useMemo, useState } from 'react'
import Panel, { EditState } from 'common/components/NewPanel'
import PanelField from 'common/components/PanelField'
import { type Dock, type DockEditable } from 'App/Docks/docks-types'
import FormField from 'common/components/FormField'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { fetchDocks, resetUpdateDock, updateDock } from 'App/Docks/docks-state'
import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import FormSelect from 'common/components/FormSelect'
import { useTranslation } from 'react-i18next'
import useRenderDate from 'common/hooks/useRenderDate'
import DockStatus from 'App/Docks/components/DockStatus'
import DockSlotsInfo from 'App/Docks/components/DockSlotsInfo'
import DocksReactContext from 'App/Docks/docks-react-context'
import DateWithRelativeTime from 'common/components/DateWithRelativeTime'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  DockColor: {
    display: 'inline-block',
  },
  DockColorBox: {
    display: 'inline-block',
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
    borderRadius: 3,
  },
  DockColorName: {
    verticalAlign: 'middle',
  },
}))

interface DockColorProps {
  color: string
}

const DockColor = ({ color }: DockColorProps) => {
  const { classes } = useStyles()

  return (
    <div className={classes.DockColor}>
      <div
        className={classes.DockColorBox}
        style={{ backgroundColor: color }}
      ></div>
      <span className={classes.DockColorName}>{color}</span>
    </div>
  )
}

interface ConfigurationPanelProps {
  dock: Dock
}

const ConfigurationPanel = ({ dock }: ConfigurationPanelProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const context = useContext(DocksReactContext)

  const [editState, setEditState] = useState<EditState>()
  const [form, setForm] = useState<DockEditable>({})

  const { organizationId, siteId } = useCurrentAccount()
  const updatedDock = useAppSelector((state) => state.docks.updatedDock)
  const updatedDockError = useAppSelector(
    (state) => state.docks.updatedDockError
  )

  useEffect(() => {
    if (updatedDockError) {
      setEditState(EditState.Error)
      dispatch(resetUpdateDock())
    } else if (updatedDock) {
      setEditState(EditState.Success)
      dispatch(resetUpdateDock())
      dispatch(fetchDocks({ organizationId, siteId, context }))
    }
  }, [dispatch, updatedDock, updatedDockError, organizationId, siteId, context])

  const colorOptions = useMemo(() => {
    const colors = [
      '#a020f0', // Default
      '#0000ff',
      '#00ffff',
      '#ff00ff',
      '#ff0000',
      '#ffff00',
    ]

    return colors.map((color) => ({
      value: color,
      label: <DockColor color={color} />,
    }))
  }, [])

  const handleSave = () => {
    setEditState(EditState.Pending)
    dispatch(
      updateDock({
        organizationId,
        siteId,
        dockId: dock.id,
        form,
        context,
      })
    )
  }

  const handleChangeField =
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, [field]: e.target.value })
    }

  const renderEditMode = () => (
    <>
      <FormField
        label={t('docks.details.general.fields.name.label')}
        value={form.name ?? dock.name}
        onChange={handleChangeField('name')}
        disabled={editState === EditState.Pending}
      />
      <FormSelect
        label={t('docks.details.general.fields.color.label')}
        value={form.color ?? dock.color}
        options={colorOptions}
        onChange={handleChangeField('color')}
        disabled={editState === EditState.Pending}
      />
    </>
  )

  return (
    <Panel
      title={t('docks.details.general.config.title')}
      editable
      editState={editState}
      onEdit={() => setEditState(EditState.Edit)}
      onCancel={() => setEditState(undefined)}
      onSave={handleSave}
      renderEditMode={renderEditMode}
    >
      <PanelField
        title={t('docks.details.general.fields.name.label')}
        value={dock.name}
      />
      <PanelField
        title={t('docks.details.general.fields.color.label')}
        value={<DockColor color={dock.color} />}
      />
    </Panel>
  )
}

interface DetailsPanelProps {
  dock: Dock
}

const DetailsPanel = ({ dock }: DetailsPanelProps) => {
  const { t } = useTranslation()
  const renderDate = useRenderDate()

  return (
    <Panel title={t('docks.details.general.details.title')}>
      <PanelField
        title={t('docks.details.general.fields.serialNumber.label')}
        value={dock.serialNumber}
      />
      <PanelField
        title={t('docks.details.general.fields.model.label')}
        value={dock.model}
      />
      <PanelField
        title={t('docks.details.general.fields.manufacturePlant.label')}
        value={dock.manufacturePlant}
      />
      <PanelField
        title={t('docks.details.general.fields.manufactureDate.label')}
        value={dock.manufactureDate != null && renderDate(dock.manufactureDate)}
      />
      <PanelField
        title={t('docks.details.general.fields.hardwareRev.label')}
        value={dock.hardwareRev}
      />
      <PanelField
        title={t('docks.details.general.fields.pcbSwRevision.label')}
        value={dock.pcbSwRevision}
      />
      <PanelField
        title={t('docks.details.general.fields.controlRev.label')}
        value={dock.controlRev}
      />
      <PanelField
        title={t('docks.details.general.fields.raspberryId.label')}
        value={dock.raspberryId}
      />
      <PanelField
        title={t('docks.details.general.fields.masterPin.label')}
        value={dock.masterPin}
      />
      <PanelField
        title={t('docks.details.general.fields.wlanAddr.label')}
        value={dock.ethernetAddressWlan}
      />
      <PanelField
        title={t('docks.details.general.fields.ethAddr.label')}
        value={dock.ethernetAddressWired}
      />
    </Panel>
  )
}

interface StatePanelProps {
  dock: Dock
}

const StatePanel = ({ dock }: StatePanelProps) => {
  const { t } = useTranslation()

  return (
    <Panel title={t('docks.details.general.state.title')}>
      <PanelField
        title={t('docks.listDocks.fields.lastActive.label')}
        value={
          dock.keepaliveLast != null ? (
            <DateWithRelativeTime datetime={dock.keepaliveLast} />
          ) : undefined
        }
      />
      <PanelField
        title={t('docks.listDocks.fields.connectedTablets.label')}
        value={<DockSlotsInfo dock={dock} />}
      />
      <PanelField
        title={t('docks.listDocks.fields.status.label')}
        value={<DockStatus dock={dock} />}
      />
      <PanelField
        title={t('docks.listDocks.fields.tunnelRequestAt.label')}
        value={
          dock.tunnelRequestAt != null ? (
            <DateWithRelativeTime datetime={dock.tunnelRequestAt} />
          ) : undefined
        }
      />
      <PanelField
        title={t('docks.listDocks.fields.tunnelResponseAt.label')}
        value={
          dock.tunnelResponseAt != null ? (
            <DateWithRelativeTime datetime={dock.tunnelResponseAt} />
          ) : undefined
        }
      />
    </Panel>
  )
}

interface DockGeneralProps {
  dock: Dock
}

const DockGeneral = ({ dock }: DockGeneralProps) => {
  return (
    <>
      <ConfigurationPanel dock={dock} />
      <DetailsPanel dock={dock} />
      <StatePanel dock={dock} />
    </>
  )
}

export default DockGeneral
