import axios from 'axios'
import useDeviceDetector from 'common/hooks/useDeviceDetector'
import { useFeature } from 'common/hooks/useFeature'
import { isFeatureEnabled } from 'common/utils/localStorage'
import { useAppSelector } from 'store'

type TrackLoginAction =
  | 'Success'
  | 'Failed'
  | 'Password requested'
  | 'Password updated'
type TrackUsersAction = 'New user invited' | 'User deleted' | 'User re-invited'
type TrackProfilesAction =
  | 'Profile created'
  | 'Profile deleted'
  | 'Category added'
  | 'Category deleted'

interface TrackBaseProps {
  orgId?: number
  userId?: number
  userRole?: string
  isMobile?: boolean
  browser?: string
  browserVersion?: string
  ipAddress?: string
  city?: string
  country?: string
}

interface TrackLoginPayload {
  email?: string
  userId?: number // this is used for SSO
  sso?: boolean
  reason?: string
}

interface TrackUsersPayload {
  // applicable to all event
  organizationId: number

  // New user invited
  role?: string
  email?: string
  siteId?: number
  language?: string

  // User deleted
  userIds?: number[]

  // User re-invited
  userId?: number

  // future extension
  [key: string]: unknown
}

interface TrackProfilesPayload {
  name?: string
  description?: string
  [key: string]: unknown
}

interface TrackLoginProps extends TrackBaseProps {
  feature: 'Login'
  action: TrackLoginAction
  payload: TrackLoginPayload
}

interface TrackUsersProps extends TrackBaseProps {
  feature: 'Users'
  action: TrackUsersAction
  payload: TrackUsersPayload
}

interface TrackProfilesProps extends TrackBaseProps {
  feature: 'Profiles'
  action: TrackProfilesAction
  payload: TrackProfilesPayload
}

export type TrackRequestProps =
  | TrackLoginProps
  | TrackUsersProps
  | TrackProfilesProps

const TRACKING_FEATURE_NAME = 'tracking'

// export this for using in non-React component (e.g. redux)
export const trackEvent = (requestBody: TrackRequestProps) => {
  // need to check feature flag again here for redux since it doesn't use useTracking hook
  if (isFeatureEnabled(TRACKING_FEATURE_NAME)) {
    // ignore await for tracking event to avoid slowing down the app
    void axios.post('/api/v2/tracking', requestBody)
  }
}

const useTracking = () => {
  const currentUser = useAppSelector((state) => state.app.currentUser)
  const ipAddress = useAppSelector((state) => state.app.ipAddress)
  const city = useAppSelector((state) => state.app.city)
  const country = useAppSelector((state) => state.app.country)
  const { role, id, organizationId } = currentUser ?? {}

  const { isEnabled } = useFeature(TRACKING_FEATURE_NAME)
  const { isMobile, browser, browserVersion } = useDeviceDetector()

  const track = ({ feature, action, payload }: TrackRequestProps) => {
    const requestBody = {
      feature,
      action,
      payload,
      isMobile,
      browser,
      browserVersion,
      userId: id,
      userRole: role,
      orgId: organizationId,
      ipAddress,
      city,
      country,
    }
    if (isEnabled) {
      trackEvent(requestBody as TrackRequestProps)
    }
  }

  return { track }
}

export default useTracking
