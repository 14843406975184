import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  styled,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { DataGrid, useGridApiRef } from '@mui/x-data-grid'
import {
  useAnalyticsData,
  useDocks,
  useSites,
} from 'App/Analytics/Analytics.hooks'
import { type GROUP_BY_OPTIONS, type SelectOption } from './Analytics.types'
import useApp from 'common/hooks/useApp'
import { fetchCustomers, fetchDistributors, UserRole } from 'App/app-state'
import { TODAY, DEFAULT_PAGE_SIZE } from 'App/Analytics/Analytics.constants'
import { useTranslation } from 'react-i18next'
import { useMuiThemeLocale } from 'useMuiThemeLocale'
import { useAppDispatch, useAppSelector } from 'store'
import { DatePicker } from '@mui/x-date-pickers'
import { getFirstDayOfCurrentMonth } from 'common/utils/date-utils'
import SelectTargetGrid from 'App/Analytics/SelectTargetGrid'
import DownloadButton from 'App/Analytics/DownloadButton'
import PrintButton from 'App/Analytics/PrintButton'
import GroupBySelect from 'App/Analytics/GroupBySelect'
import { NoData, Instruction } from 'App/Analytics/NoData'
import { usePrivileges } from 'common/hooks/usePrivileges'
import { Action, PrivilegeSubject } from 'common/utils/privileges'

const GroupByContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(4),
  flexDirection: 'row',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    alignItems: 'flex-start',
    gap: theme.spacing(2),
  },
}))

const DataGridContainer = styled(Box)(({ theme }) => ({
  height: '628px', // 10 data rows (each = 52px) + header (56px) + footer (52px)
  width: '100%',
}))

const CheckboxContainer = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: theme.direction === 'ltr' ? '-11px' : '16px',
  marginRight: theme.direction === 'ltr' ? '16px' : '-11px',
}))

export const AnalyticsComponent = () => {
  const [selectedPartner, setSelectedPartner] = useState<SelectOption | null>(
    null
  )
  const [selectedOrg, setSelectedOrg] = useState<SelectOption | null>(null)
  const [selectedSite, setSelectedSite] = useState<SelectOption | null>(null)
  const [selectedDock, setSelectedDock] = useState<SelectOption | null>(null)
  const [fromDate, setFromDate] = useState<Date | null>(
    getFirstDayOfCurrentMonth()
  )
  const [toDate, setToDate] = useState<Date | null>(TODAY)
  const [groupBy, setGroupBy] = useState<GROUP_BY_OPTIONS | ''>('')
  const [paginationModel, setPaginationModel] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0, // first page
  })
  const [excludeDeleted, setExcludeDeleted] = useState(true)
  const { role, organization, site } = useApp()
  const { can } = usePrivileges()
  const currentUser = useAppSelector((state) => state.app.currentUser)
  const isDistributor = currentUser?.isDistributor
  const skipFetchingSites = !can(Action.View, PrivilegeSubject.Sites)
  const {
    columns,
    rows,
    isLoading: isLoadingAnalyticsData,
  } = useAnalyticsData({
    distributorId: selectedPartner?.id,
    orgId: selectedOrg?.id,
    siteId: selectedSite?.id,
    dockId: selectedDock?.id,
    startDate: fromDate,
    endDate: toDate,
    groupByTime: groupBy || undefined,
    excludeDeleted,
    role,
  })
  const distributors = useAppSelector((state) => state.app.distributors)
  const customers = useAppSelector((state) => state.app.customers)
  const { sites } = useSites(selectedOrg?.id, excludeDeleted, skipFetchingSites)
  const { docks } = useDocks(selectedOrg?.id, selectedSite?.id, excludeDeleted)
  const { dataGridLocale } = useMuiThemeLocale()
  const { t } = useTranslation()
  const apiRef = useGridApiRef()

  const dispatch = useAppDispatch()

  useEffect(() => {
    switch (role) {
      case UserRole.HubletAdmin:
        // fetchDistributors if it hasn't been fetched yet
        if (!distributors) {
          dispatch(fetchDistributors())
        }
        break
      case UserRole.DistributorAdmin:
        setSelectedPartner({
          id: organization.id,
          label: organization.name,
        })
        break
      case UserRole.OrgAdmin:
        setSelectedOrg({ id: organization.id, label: organization.name })
        break
      case UserRole.SiteAdmin:
        setSelectedOrg({ id: organization.id, label: organization.name })
        setSelectedSite(site ? { id: site.id, label: site.name } : null)
        break
      default:
        break
    }
  }, [dispatch, distributors, isDistributor, organization, role, site])

  useEffect(() => {
    if (selectedPartner?.id) {
      dispatch(fetchCustomers(selectedPartner.id))
    }
  }, [dispatch, selectedPartner?.id])

  const showSelectDistributor = can(Action.View, PrivilegeSubject.Distributors)
  const showSelectOrg = can(Action.View, PrivilegeSubject.Customers)
  const showSelectSite = can(Action.View, PrivilegeSubject.Sites)
  const showSelectDock = can(Action.View, PrivilegeSubject.Docks)

  return (
    <Box flexDirection="column" sx={{ width: '100%' }}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1" marginBottom={2}>
          {t('analytics.instruction1')}
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        xs={12}
        justifyContent="space-between"
        marginLeft={0}
      >
        <Grid
          item
          container
          spacing={1}
          sm={12}
          md={showSelectDistributor ? 4 : 6}
          sx={{ paddingLeft: '0 !important;' }} // override padding of the MuiGrid-item style for better layout adjustment
        >
          <Grid item xs="auto" data-testid="select-date-from">
            <DatePicker
              label={t('analytics.from')}
              onChange={setFromDate}
              value={fromDate}
            />
          </Grid>
          <Grid item xs="auto" data-testid="select-date-to">
            <DatePicker
              label={t('analytics.to')}
              onChange={setToDate}
              value={toDate}
            />
          </Grid>
        </Grid>

        <Grid
          item
          container
          spacing={1}
          justifyContent="flex-end"
          alignItems="flex-end"
          xs={12}
          sm={12}
          md={showSelectDistributor ? 8 : 6}
          sx={{ paddingLeft: '0 !important;' }} // override padding of the MuiGrid-item style for better layout adjustment
        >
          <SelectTargetGrid
            show={showSelectDistributor}
            compact={showSelectDistributor}
            options={distributors.map(({ id, name }) => ({ id, label: name }))}
            selectedOption={selectedPartner}
            onChange={(newValue) => {
              setSelectedPartner(newValue)
              setSelectedOrg(null)
              setSelectedSite(null)
              setSelectedDock(null)
            }}
            label={t('analytics.selectPartner')}
          />
          <SelectTargetGrid
            show={showSelectOrg}
            compact={showSelectDistributor}
            options={customers.map(({ id, name }) => ({ id, label: name }))}
            selectedOption={selectedOrg}
            onChange={(newValue) => {
              setSelectedOrg(newValue)
              setSelectedSite(null)
              setSelectedDock(null)
            }}
            label={t('analytics.selectOrg')}
          />
          <SelectTargetGrid
            show={showSelectSite}
            compact={showSelectDistributor}
            options={sites}
            selectedOption={selectedSite}
            onChange={(newValue) => {
              setSelectedSite(newValue)
              setSelectedDock(null)
            }}
            label={t('analytics.selectSite')}
          />
          <SelectTargetGrid
            show={showSelectDock}
            compact={showSelectDistributor}
            options={docks}
            selectedOption={selectedDock}
            onChange={(newValue) => setSelectedDock(newValue)}
            label={t('analytics.selectDock')}
          />
        </Grid>
      </Grid>

      <CheckboxContainer
        control={
          <Checkbox
            checked={excludeDeleted}
            onChange={(e) => setExcludeDeleted(e.target.checked)}
          />
        }
        label={t('analytics.excludeDeleted')}
      />

      <Typography variant="h5" marginTop={4}>
        {t('analytics.dataTable')}
      </Typography>

      <GroupByContainer>
        <Typography variant="body1">{t('analytics.instruction2')}</Typography>
        <Box display="flex" flexDirection="row" gap={1} alignItems="center">
          <Typography variant="body1">{t('analytics.groupBy')}</Typography>
          <GroupBySelect
            onChange={(value) => setGroupBy(value as '' | GROUP_BY_OPTIONS)}
            selectedOption={groupBy}
          />
          <DownloadButton apiRef={apiRef} disabled={!rows.length} />
          <PrintButton apiRef={apiRef} disabled={!rows.length} />
        </Box>
      </GroupByContainer>

      <DataGridContainer>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          disableRowSelectionOnClick
          slots={{
            noRowsOverlay: fromDate && toDate ? NoData : Instruction,
          }}
          sx={{ '--DataGrid-overlayHeight': '100px', marginBottom: 4 }}
          loading={isLoadingAnalyticsData}
          localeText={{
            ...dataGridLocale.components.MuiDataGrid.defaultProps.localeText,
            columnMenuManageColumns: t('analytics.manageColumns'), // this text does not exist in the DataGrid's locale
          }}
          apiRef={apiRef}
        />
      </DataGridContainer>
    </Box>
  )
}
