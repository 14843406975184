import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UsersApi from 'api/users-api'
import { RootState } from 'store'
import { type User, type UserEditable } from 'App/Users/users-types'
import { getCommonTrackingData } from 'common/utils/tracking-utils'
import { trackEvent, TrackRequestProps } from 'common/hooks/useTracking'

interface UsersState {
  users: User[]
  fetchedUsers: boolean
  updatedUser: boolean
  deletedUser: boolean
  invitedUser: boolean
  reinvitedUser: boolean
}

const initialState: UsersState = {
  users: [],
  fetchedUsers: false,
  updatedUser: false,
  deletedUser: false,
  invitedUser: false,
  reinvitedUser: false,
}

export const fetchOrganizationUsers = createAsyncThunk(
  'users/fetchOrganizationUsers',
  async (props: { organizationId: number }) =>
    UsersApi.fetchOrganizationUsers(props.organizationId)
)

export const fetchSiteUsers = createAsyncThunk(
  'users/fetchSiteUsers',
  async (props: { organizationId: number; siteId: number }) =>
    UsersApi.fetchSiteUsers(props.organizationId, props.siteId)
)

export const inviteOrganizationUser = createAsyncThunk(
  'users/inviteOrganizationUser',
  async (
    params: {
      organizationId: number
      email: string
      language: string
      role: string
    },
    { getState }
  ) => {
    const { organizationId, email, language, role } = params
    const res = await UsersApi.createOrganizationUser(
      organizationId,
      email,
      language,
      role
    )

    const state = getState() as RootState
    const commonTrackingData = getCommonTrackingData(state)

    const requestBody: TrackRequestProps = {
      ...commonTrackingData,
      feature: 'Users',
      action: 'New user invited',
      payload: { organizationId, email, role, language },
    }

    trackEvent(requestBody)

    return res
  }
)

export const inviteSiteUser = createAsyncThunk(
  'users/inviteSiteUser',
  async (
    params: {
      organizationId: number
      siteId: number
      email: string
      language: string
      role: string
    },
    { getState }
  ) => {
    const { organizationId, siteId, email, language, role } = params
    const res = await UsersApi.createSiteUser(
      organizationId,
      siteId,
      email,
      language,
      role
    )

    const state = getState() as RootState
    const commonTrackingData = getCommonTrackingData(state)

    const requestBody: TrackRequestProps = {
      ...commonTrackingData,
      feature: 'Users',
      action: 'New user invited',
      payload: { organizationId, siteId, email, role, language },
    }

    trackEvent(requestBody)

    return res
  }
)

export const updateOrganizationUser = createAsyncThunk(
  'users/updateOrganizationUser',
  async (props: {
    organizationId: number
    userId: number
    form: UserEditable
  }) =>
    UsersApi.updateOrganizationUser(
      props.organizationId,
      props.userId,
      props.form
    )
)

export const updateSiteUser = createAsyncThunk(
  'users/updateSiteUser',
  async (props: {
    organizationId: number
    siteId: number
    userId: number
    form: UserEditable
  }) =>
    UsersApi.updateSiteUser(
      props.organizationId,
      props.siteId,
      props.userId,
      props.form
    )
)

export const deleteOrganizationUsers = createAsyncThunk(
  'users/deleteOrganizationUsers',
  async (
    props: { organizationId: number; userIds: number[] },
    { getState }
  ) => {
    const { organizationId, userIds } = props

    const allPromises: Promise<void>[] = []
    props.userIds.forEach((userId) => {
      allPromises.push(UsersApi.deleteOrganizationUser(organizationId, userId))
    })
    const res = await Promise.all(allPromises)

    const state = getState() as RootState
    const commonTrackingData = getCommonTrackingData(state)
    const requestBody: TrackRequestProps = {
      ...commonTrackingData,
      feature: 'Users',
      action: 'User deleted',
      payload: { organizationId, userIds },
    }
    trackEvent(requestBody)

    return res
  }
)

export const deleteSiteUsers = createAsyncThunk(
  'users/deleteSiteUsers',
  async (
    props: {
      organizationId: number
      siteId: number
      userIds: number[]
    },
    { getState }
  ) => {
    const { organizationId, userIds, siteId } = props

    const allPromises: Promise<void>[] = []
    props.userIds.forEach((userId) => {
      allPromises.push(UsersApi.deleteSiteUser(organizationId, siteId, userId))
    })
    const res = await Promise.all(allPromises)

    const state = getState() as RootState
    const commonTrackingData = getCommonTrackingData(state)
    const requestBody: TrackRequestProps = {
      ...commonTrackingData,
      feature: 'Users',
      action: 'User deleted',
      payload: { organizationId, siteId, userIds },
    }
    trackEvent(requestBody)

    return res
  }
)

export const reinviteOrganizationUser = createAsyncThunk(
  'users/reinviteOrganizationUser',
  async (props: { organizationId: number; userId: number }, { getState }) => {
    const { organizationId, userId } = props
    const res = await UsersApi.reinviteOrganizationUser(organizationId, userId)

    const state = getState() as RootState
    const commonTrackingData = getCommonTrackingData(state)
    const requestBody: TrackRequestProps = {
      ...commonTrackingData,
      feature: 'Users',
      action: 'User re-invited',
      payload: { organizationId, userId },
    }
    trackEvent(requestBody)

    return res
  }
)

export const reinviteSiteUser = createAsyncThunk(
  'users/reinviteSiteUser',
  async (
    props: { organizationId: number; siteId: number; userId: number },
    { getState }
  ) => {
    const { organizationId, siteId, userId } = props
    const res = await UsersApi.reinviteSiteUser(organizationId, siteId, userId)

    const state = getState() as RootState
    const commonTrackingData = getCommonTrackingData(state)
    const requestBody: TrackRequestProps = {
      ...commonTrackingData,
      feature: 'Users',
      action: 'User re-invited',
      payload: { organizationId, siteId, userId },
    }
    trackEvent(requestBody)

    return res
  }
)

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetInvitedUser: (state) => {
      state.invitedUser = false
      return state
    },
    resetUpdatedUser: (state) => {
      state.updatedUser = false
      return state
    },
    resetDeletedUser: (state) => {
      state.deletedUser = false
      return state
    },
    resetReinviteduser: (state) => {
      state.reinvitedUser = false
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizationUsers.fulfilled, (state, { payload }) => {
      state.users = payload
      state.fetchedUsers = true
      return state
    })

    builder.addCase(fetchSiteUsers.fulfilled, (state, { payload }) => {
      state.users = payload
      state.fetchedUsers = true
      return state
    })

    builder.addCase(inviteOrganizationUser.fulfilled, (state) => {
      state.invitedUser = true
      return state
    })

    builder.addCase(inviteSiteUser.fulfilled, (state) => {
      state.invitedUser = true
      return state
    })

    builder.addCase(updateOrganizationUser.fulfilled, (state) => {
      state.updatedUser = true
      return state
    })

    builder.addCase(updateSiteUser.fulfilled, (state) => {
      state.updatedUser = true
      return state
    })

    builder.addCase(deleteOrganizationUsers.fulfilled, (state) => {
      state.deletedUser = true
      return state
    })

    builder.addCase(deleteSiteUsers.fulfilled, (state) => {
      state.deletedUser = true
      return state
    })

    builder.addCase(reinviteOrganizationUser.fulfilled, (state) => {
      state.reinvitedUser = true
      return state
    })

    builder.addCase(reinviteSiteUser.fulfilled, (state) => {
      state.reinvitedUser = true
      return state
    })
  },
})

export const {
  resetInvitedUser,
  resetUpdatedUser,
  resetDeletedUser,
  resetReinviteduser,
} = usersSlice.actions

export default usersSlice.reducer
