import React from 'react'
import { Box, List, ListSubheader, styled } from '@mui/material'
import useApp from 'common/hooks/useApp'
import { UserRole } from 'App/app-state'
import {
  type SvgIconComponent,
  Dashboard as DashboardIcon,
  CallSplitOutlined as SplitIcon,
  StoreMallDirectoryOutlined as StoreMallDirectoryIcon,
  LocationOnOutlined as LocationOnIcon,
  PersonOutlined as PersonIcon,
  WifiOutlined as WifiIcon,
  VideoLabelOutlined as VideoLabelIcon,
  TabletAndroidOutlined as TabletAndroidIcon,
  EventOutlined as EventIcon,
  SettingsOutlined as SettingsIcon,
  VpnKeyOutlined as KeyIcon,
  MobileFriendlyOutlined as MobileFriendlyIcon,
  GroupOutlined as GroupIcon,
  BuildOutlined as BuildIcon,
  TranslateOutlined as TranslateIcon,
  Insights as InsightsIcon,
  Analytics as AnalyticsIcon,
  HelpCenterOutlined as HelpCenterIcon,
  AnalyticsOutlined as AnalyticsOutlinedIcon,
  AssignmentOutlined as AssignmentIcon,
  Launch as LaunchIcon,
} from '@mui/icons-material'
import { PATHS } from 'common/constants'
import PageSideMenuItem from 'common/components/PageSideMenuItem'
import { useTranslation } from 'react-i18next'
import logo from 'common/hublet-manager-logo.svg'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'store'
import SelectLanguage from 'common/components/SelectLanguage'

interface MenuItem {
  title: string
  Icon: SvgIconComponent
  link: string
  visible: boolean
  isExternal?: boolean
}

export const SIDE_MENU_WIDTH = 280 // pixels

const menuAccountItems = (role: UserRole, adminMode: boolean): MenuItem[] => [
  {
    title: 'app.nav.dashboard',
    Icon: DashboardIcon,
    link: '/dashboard',
    visible: true,
  },
  {
    title: 'app.nav.distributors',
    Icon: SplitIcon,
    link: '/distributors',
    visible: adminMode,
  },
  {
    title: 'app.nav.customers',
    Icon: StoreMallDirectoryIcon,
    link: '/customers',
    visible:
      role === UserRole.HubletAdmin || role === UserRole.DistributorAdmin,
  },
  {
    title: 'app.nav.sites',
    Icon: LocationOnIcon,
    link: '/sites',
    visible:
      role === UserRole.HubletAdmin ||
      role === UserRole.DistributorAdmin ||
      role === UserRole.OrgAdmin,
  },
  {
    title: 'app.nav.profiles',
    Icon: PersonIcon,
    link: '/profiles',
    visible: role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.posts',
    Icon: EventIcon,
    link: '/posts',
    visible: role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.loans',
    Icon: MobileFriendlyIcon,
    link: '/loans',
    visible: role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.codes',
    Icon: KeyIcon,
    link: '/codes',
    visible: role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.analytics',
    Icon: AnalyticsOutlinedIcon,
    link: PATHS.analytics,
    visible:
      adminMode ||
      role === UserRole.HubletAdmin ||
      role === UserRole.OrgAdmin ||
      role === UserRole.SiteAdmin ||
      role === UserRole.DistributorAdmin,
  },
  {
    title: 'app.nav.topapps',
    Icon: AnalyticsIcon,
    link: '/top-apps',
    visible: adminMode,
  },
  {
    title: 'app.nav.helpCenter',
    Icon: HelpCenterIcon,
    link: '/zendesk',
    visible: true,
    isExternal: true,
  },
]

const menuAdministrationItems = (
  role: UserRole,
  adminMode: boolean
): MenuItem[] => [
  {
    title: 'app.nav.users',
    Icon: GroupIcon,
    link: '/users',
    visible:
      role === UserRole.HubletAdmin ||
      role === UserRole.DistributorAdmin ||
      role === UserRole.OrgAdmin ||
      role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.settings',
    Icon: SettingsIcon,
    link: '/site-settings',
    visible: role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.settings',
    Icon: SettingsIcon,
    link: '/distributor-settings',
    visible: role === UserRole.DistributorAdmin,
  },
  {
    title: 'app.nav.settings',
    Icon: SettingsIcon,
    link: '/customer-settings',
    visible: role === UserRole.OrgAdmin,
  },
  {
    title: 'app.nav.networks',
    Icon: WifiIcon,
    link: '/networks',
    visible: role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.integrations',
    Icon: BuildIcon,
    link: '/integrations',
    visible: role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.integrationStatistics',
    Icon: InsightsIcon,
    link: '/integration-statistics',
    visible: adminMode,
  },
  {
    title: 'app.nav.tablets',
    Icon: TabletAndroidIcon,
    link: '/tablets',
    visible: role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.docks',
    Icon: VideoLabelIcon,
    link: '/docks',
    visible:
      role === UserRole.HubletAdmin ||
      role === UserRole.DistributorAdmin ||
      role === UserRole.OrgAdmin ||
      role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.translations',
    Icon: TranslateIcon,
    link: '/translations',
    visible: adminMode || role === UserRole.Translator,
  },
  {
    title: 'Super-admin',
    Icon: AssignmentIcon,
    link: '/admin',
    visible: adminMode,
  },
]

const menuManufacturerItems = (): MenuItem[] => [
  {
    title: 'app.nav.tablets',
    Icon: TabletAndroidIcon,
    link: '/stock-tablets',
    visible: true,
  },
  {
    title: 'app.nav.docks',
    Icon: VideoLabelIcon,
    link: '/manufacturer/docks',
    visible: true,
  },
]

const LogoContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(3),
  '& img': {
    height: theme.spacing(12.5),
    margin: theme.spacing(1),
  },
}))

const StyledSubHeader = styled(ListSubheader)(({ theme }) => ({
  marginBottom: '8px',
  padding: '0 32px',
  fontSize: '14px',
  textTransform: 'uppercase',
  fontWeight: 'normal',
  color: 'GrayText',
  lineHeight: '19px',
}))

const StyledContainer = styled(Box)(({ theme }) => ({
  minWidth: `${SIDE_MENU_WIDTH}px`,
  maxWidth: `${SIDE_MENU_WIDTH}px`,
  height: '100vh',
  overflowY: 'scroll',
  boxShadow: 'rgba(108, 37, 118, 0.1) 0px 4px 30px',

  '& > nav': {
    marginTop: theme.spacing(4.5),
    '&:first-of-type': {
      marginTop: 0,
    },
  },
}))

const PageSideMenu = () => {
  const { role, adminMode } = useApp()
  const currentUser = useAppSelector((state) => state.app.currentUser)
  const { t } = useTranslation()

  const isManufacturerAdmin =
    currentUser?.isManufacturer &&
    (role === UserRole.OrgAdmin || role === UserRole.DistributorAdmin)

  const showManufacturerMenu =
    role === UserRole.HubletAdmin ||
    role === UserRole.Manufacturer ||
    isManufacturerAdmin

  return (
    <StyledContainer>
      <LogoContainer>
        <Link to="/">
          <img src={logo} alt="Hublet" />
        </Link>
      </LogoContainer>

      <List
        component="nav"
        subheader={
          <StyledSubHeader disableSticky>
            {t('app.nav.account')}
          </StyledSubHeader>
        }
      >
        {menuAccountItems(role, adminMode).map((item) => (
          <PageSideMenuItem item={item} key={item.link} />
        ))}
      </List>

      <List
        component="nav"
        subheader={
          <StyledSubHeader disableSticky>
            {t('app.nav.administration')}
          </StyledSubHeader>
        }
      >
        {menuAdministrationItems(role, adminMode).map((item) => (
          <PageSideMenuItem item={item} key={item.link} />
        ))}
      </List>

      {showManufacturerMenu && (
        <List
          component="nav"
          subheader={
            <StyledSubHeader disableSticky>
              {t('app.nav.manufacturer')}
            </StyledSubHeader>
          }
        >
          {menuManufacturerItems().map((item) => (
            <PageSideMenuItem item={item} key={item.link} />
          ))}
        </List>
      )}

      <List>
        <PageSideMenuItem
          item={{
            title: 'app.nav.oldManager',
            Icon: LaunchIcon,
            link: window.location.origin,
            visible: true,
            isExternal: true,
          }}
        />
      </List>

      <Box padding={2} display="flex" flexDirection="column">
        <SelectLanguage />
      </Box>
    </StyledContainer>
  )
}

export default PageSideMenu
