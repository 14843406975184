import { Print } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { type GridApiCommunity } from '@mui/x-data-grid/internals'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface PrintButtonProps {
  apiRef: React.MutableRefObject<GridApiCommunity>
  disabled: boolean
}

const PrintButton = ({ apiRef, disabled }: PrintButtonProps) => {
  const { t } = useTranslation()

  return (
    <IconButton
      color="primary"
      title={t('analytics.print')}
      aria-label={t('analytics.print')}
      onClick={() =>
        apiRef.current.exportDataAsPrint({
          fileName: 'Hublet',
          pageStyle:
            '@page { size: auto; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 40px !important; min-width: 1200px; } }',
        })
      }
      disabled={disabled}
    >
      <Print />
    </IconButton>
  )
}

export default PrintButton
