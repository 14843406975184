import { Error } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Typography,
  styled,
  alpha,
} from '@mui/material'
import {
  useConfirmPasswordMutation,
  useEmailPasswordLoginMutation,
} from 'App/CognitoLogin/cogonit-login-rtk-api'
import LoginPageWrapper from 'App/Login/components/LoginPageWrapper'
import { fetchCurrentUser } from 'App/app-state'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import usePwdComplexityChecks from 'App/Login/Login.hooks'
import useTracking from 'common/hooks/useTracking'

const FormStyled = styled('form')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}))

const SeparatorBox = styled(Box)(({ theme }) => ({
  width: '14.5rem',
  margin: theme.spacing(3, 0),
}))

const ButtonStyled = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}))

const ErrorListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.error.main, 0.08),
}))

interface InputError {
  password: string
  confirmPassword: string
  code: string
}

interface PasswordRecoveryStep2Props {
  email: string
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const PasswordRecoveryStep2 = ({
  email,
  setStep,
}: PasswordRecoveryStep2Props) => {
  const [code, setCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [isDirty, setIsDirty] = useState(false)
  const [inputError, setInputError] = useState<InputError>({
    password: '',
    code: '',
    confirmPassword: '',
  })
  const [postConfirmPassword, { reset: resetConfirmPasswordData }] =
    useConfirmPasswordMutation()
  const [postLogin] = useEmailPasswordLoginMutation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { track } = useTracking()

  const pwdComplexityChecks = usePwdComplexityChecks(newPassword)

  const isValidInput = () => {
    let codeError = ''
    let passwordError = ''
    let confirmPasswordError = ''

    if (!newPassword) {
      passwordError = t('loginv2.error.requiredField')
    }

    if (!confirmNewPassword) {
      confirmPasswordError = t('loginv2.error.requiredField')
    } else if (confirmNewPassword !== newPassword) {
      confirmPasswordError = t('loginv2.error.passwordNotMatched')
    }

    if (!code) {
      codeError = t('loginv2.error.requiredField')
    }

    setInputError({
      code: codeError,
      password: passwordError,
      confirmPassword: confirmPasswordError,
    })

    if (
      !codeError &&
      !passwordError &&
      !confirmPasswordError &&
      pwdComplexityChecks.length === 0
    ) {
      return true
    }
    return false
  }

  const resetInput = () => {
    setCode('')
    setNewPassword('')
    setErrorMsg('')
    setInputError({
      code: '',
      password: '',
      confirmPassword: '',
    })
  }

  const resetInputState = () => {
    resetConfirmPasswordData()
    setErrorMsg('')
  }

  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value)
    resetInputState()
    setInputError((prev) => ({ ...prev, password: '' }))
    setIsDirty(true)
  }

  const handleConfirmCode = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!isValidInput()) {
      return
    }

    postConfirmPassword({ email, code, newPassword })
      .unwrap()
      .then((res) => {
        if (res.isSuccess) {
          resetInput()

          track({
            feature: 'Login',
            action: 'Password updated',
            payload: { email },
          })

          // log user in after successfully reset user's password
          postLogin({ username: email, password: newPassword })
            .unwrap()
            .then(() => {
              dispatch(fetchCurrentUser())
              navigate('/dashboard')
            })
        } else {
          setErrorMsg(
            t(`loginv2.error.${res.error?.code ?? 'InternalErrorException'}`)
          )
        }
      })
      .catch((error) => {
        console.warn('Failed to confirm forgot password', error)
        setErrorMsg(t('loginv2.error.InternalErrorException'))
      })
  }

  return (
    <LoginPageWrapper title={t('loginv2.changePassword')}>
      <FormStyled onSubmit={handleConfirmCode}>
        <Typography variant="body1" marginBottom={5} align="center">
          {t('loginv2.forgotPasswordInstruction2')}
        </Typography>
        {errorMsg && (
          <Alert severity="error" sx={{ marginBottom: 5 }}>
            {errorMsg}
          </Alert>
        )}
        <TextField
          value={code}
          onChange={(e) => {
            setCode(e.target.value)
            resetInputState()
            setInputError((prev) => ({ ...prev, code: '' }))
          }}
          placeholder={t('loginv2.passwordResetCode')}
          label={t('loginv2.passwordResetCode')}
          size="small"
          error={Boolean(inputError.code)}
          helperText={inputError.code}
        />
        <TextField
          value={newPassword}
          onChange={handlePasswordInput}
          placeholder={t('loginv2.newPassword')}
          label={t('loginv2.newPassword')}
          type="password"
          size="small"
          error={Boolean(inputError.password)}
          helperText={inputError.password}
        />
        <TextField
          value={confirmNewPassword}
          onChange={(e) => {
            setConfirmNewPassword(e.target.value)
            resetInputState()
            setInputError((prev) => ({ ...prev, confirmPassword: '' }))
          }}
          placeholder={t('loginv2.confirmNewPassword')}
          label={t('loginv2.confirmNewPassword')}
          type="password"
          size="small"
          error={Boolean(inputError.confirmPassword)}
          helperText={inputError.confirmPassword}
        />

        {isDirty && pwdComplexityChecks.length > 0 && (
          <List sx={{ paddingTop: 2, paddingBottom: 0 }}>
            {pwdComplexityChecks.map((item) => (
              <ErrorListItem key={item} dense>
                <ListItemIcon sx={{ minWidth: '2rem' }}>
                  <Error sx={{ color: 'error.main' }} />
                </ListItemIcon>
                {t(item)}
              </ErrorListItem>
            ))}
          </List>
        )}

        <ButtonWrapper>
          <ButtonStyled variant="contained" color="primary" type="submit">
            {t('loginv2.savePassword')}
          </ButtonStyled>
          <SeparatorBox>
            <Divider />
          </SeparatorBox>
          <ButtonStyled
            onClick={() => {
              resetInput()
              setStep(1)
            }}
            variant="outlined"
          >
            {t('loginv2.goBack')}
          </ButtonStyled>
        </ButtonWrapper>
      </FormStyled>
    </LoginPageWrapper>
  )
}

export default PasswordRecoveryStep2
