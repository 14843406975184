import { FileDownload } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { type GridApiCommunity } from '@mui/x-data-grid/internals'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface DownloadButtonProps {
  apiRef: React.MutableRefObject<GridApiCommunity>
  disabled: boolean
}

const DownloadButton = ({ apiRef, disabled }: DownloadButtonProps) => {
  const { t } = useTranslation()

  return (
    <IconButton
      color="primary"
      title={t('analytics.exportToCSV')}
      aria-label={t('analytics.exportToCSV')}
      onClick={() => apiRef.current.exportDataAsCsv({ fileName: 'Hublet' })}
      disabled={disabled}
    >
      <FileDownload />
    </IconButton>
  )
}

export default DownloadButton
