import axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import {
  type CurrentUser,
  UserRole,
  type CurrentUserContext,
} from 'App/app-state'
import { type LoginForm, type PostLoginResponse } from 'App/Login/login-state'
import {
  type InvitedUser,
  type User,
  type UserEditable,
} from 'App/Users/users-types'

const toUserModel = (data: any): User => ({
  id: data.id,
  organizationId: data.organizationId,
  siteId: data.siteId ?? undefined,
  email: data.email,
  role: data.role,
  firstName: data.firstName,
  lastName: data.lastName,
  rcvExpLoansMail: data.rcvExpLoansMail,
  invitationExpiresAt: data.invitationExpiresAt,
  isLocked: data.isLocked,
})

const toInvitedUserModel = (data: any): InvitedUser => ({
  id: data.id,
  email: data.email,
  role: data.role,
})

class UsersApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchOrganizationUsers(organizationId: number): Promise<User[]> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/users`
    const { data } = await axios.get(apiUrl)
    return data.map(toUserModel)
  }

  static async fetchSiteUsers(
    organizationId: number,
    siteId: number
  ): Promise<User[]> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/users`
    const { data } = await axios.get(apiUrl)
    return data.map(toUserModel)
  }

  static async createOrganizationUser(
    organizationId: number,
    email: string,
    languageCode: string,
    role: string
  ): Promise<void> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/users`
    const userData = { email, languageCode, role }

    await axios.post(apiUrl, userData)
  }

  static async createSiteUser(
    organizationId: number,
    siteId: number,
    email: string,
    languageCode: string,
    role: string
  ): Promise<void> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/users`
    const userData = { email, languageCode, role }

    await axios.post(apiUrl, userData)
  }

  static async updateOrganizationUser(
    organizationId: number,
    userId: number,
    form: UserEditable
  ): Promise<void> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/users/${userId}`
    await axios.put(apiUrl, form)
  }

  static async updateSiteUser(
    organizationId: number,
    siteId: number,
    userId: number,
    form: UserEditable
  ): Promise<void> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/users/${userId}`
    await axios.put(apiUrl, form)
  }

  static async deleteOrganizationUser(
    organizationId: number,
    userId: number
  ): Promise<void> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/users/${userId}`
    await axios.delete(apiUrl)
  }

  static async deleteSiteUser(
    organizationId: number,
    siteId: number,
    userId: number
  ): Promise<void> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/users/${userId}`
    await axios.delete(apiUrl)
  }

  static async fetchInvitedUserByToken(token: string): Promise<InvitedUser> {
    const apiUrl = `${API_BASE_URL}/users/invitations/${encodeURIComponent(
      token
    )}`
    const { data } = await axios.get(apiUrl)
    return toInvitedUserModel(data)
  }

  static async acceptInvitation(
    token: string,
    firstName: string,
    lastName: string,
    password: string
  ): Promise<void> {
    const apiUrl = `${API_BASE_URL}/users/invitations/${encodeURIComponent(
      token
    )}/accept`
    const userData = {
      firstName,
      lastName,
      password,
    }

    await axios.post(apiUrl, userData)
  }

  static async login(form: LoginForm): Promise<PostLoginResponse> {
    const { data } = await axios.post(`${API_BASE_URL}/user/login`, form)
    return data as PostLoginResponse
  }

  static async currentUser(
    userContext: CurrentUserContext | undefined
  ): Promise<CurrentUser> {
    if (userContext?.role === UserRole.DistributorAdmin) {
      userContext.role = UserRole.OrgAdmin
    }

    const { data } = await axios.get(`${API_BASE_URL}/user/current-user`, {
      params: { context: userContext },
    })

    const currentUser: CurrentUser = {
      id: data.userId,
      login: data.login,
      firstName: data.firstName ?? '',
      lastName: data.lastName ?? '',
      organizationId: data.organizationId,
      orgName: data.orgName,
      siteId: data.siteId,
      siteName: data.siteName,
      role: data.role,
      isDistributor: data.isDistributor,
      distributorId: data.distributorId ?? undefined,
      isManufacturer: data.isManufacturer ?? false,
      loginSuccessTotal: data.loginSuccessTotal,
      expiresAt: (data.exp ?? 0) * 1000, // `data.exp` is in seconds
      rules: data.rules,
    }

    if (data.isDistributor && data.role === UserRole.OrgAdmin) {
      currentUser.role = UserRole.DistributorAdmin
    }

    return currentUser
  }

  static async reinviteOrganizationUser(
    organizationId: number,
    userId: number
  ): Promise<void> {
    const url = `${API_BASE_URL}/organizations/${organizationId}/users/${userId}/reinvite`
    await axios.post(url)
  }

  static async reinviteSiteUser(
    organizationId: number,
    siteId: number,
    userId: number
  ): Promise<void> {
    const url = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/users/${userId}/reinvite`
    await axios.post(url)
  }
}

export default UsersApi
