import { Box, styled } from '@mui/material'
import PageBgImage from 'common/components/PageBackgroundImage'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import PageSideMenu, { SIDE_MENU_WIDTH } from 'common/components/PageSideMenu'
import PageTopMenu from 'common/components/PageTopMenu'
import InstallationWizard from 'App/InstallationWizard'
import useApp from 'common/hooks/useApp'
import { invalidateCurrentUser, UserRole } from 'App/app-state'
import { useLocation } from 'react-router-dom'

interface PageProps {
  children?: React.ReactNode
  bgImageUrl?: string | null
}

const PageContainer = styled(Box)<{ direction: 'rtl' | 'ltr' }>(
  ({ theme, direction }) => ({
    width: '100vw',
    height: '100vh',
    direction,
    display: 'flex',
  })
)

const PageContentWrapper = styled(Box)<{ showSideMenu: boolean }>(
  ({ theme, showSideMenu }) => ({
    display: 'flex',
    flex: 1,
    minWidth: showSideMenu ? `'calc(100vw - ${SIDE_MENU_WIDTH}px)'` : '100vw',
    maxWidth: showSideMenu ? `'calc(100vw - ${SIDE_MENU_WIDTH}px)'` : '100vw',
    flexDirection: 'column',
    height: '100vh',
    overflowY: 'scroll',
  })
)

const PageContent = styled(Box)<{ direction: 'rtl' | 'ltr' }>(
  ({ theme, direction }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    direction,
    padding: theme.spacing(6),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  })
)

const Page = ({ children, bgImageUrl }: PageProps) => {
  const [showSideMenu, setShowSideMenu] = useState(true)
  const dispatch = useAppDispatch()
  const languageDirection = useAppSelector(
    (state) => state.app.languageDirection
  )
  const location = useLocation()
  const currentUser = useAppSelector((state) => state.app.currentUser)

  const { role, organization, site } = useApp()

  if (!currentUser || currentUser.expiresAt < Date.now()) {
    dispatch(invalidateCurrentUser())
    return null
  }

  const toggleSideMenu = () => setShowSideMenu(!showSideMenu)
  const showBgImage = Boolean(
    bgImageUrl || location.pathname.startsWith('/profiles/')
  )
  const showInstallationWizard =
    role === UserRole.SiteAdmin && site !== undefined

  return (
    <PageContainer direction={languageDirection}>
      {showSideMenu && <PageSideMenu />}

      <PageContentWrapper showSideMenu={showSideMenu}>
        <PageTopMenu toggleShowSideMenu={toggleSideMenu} />

        {showBgImage && <PageBgImage imageUrl={bgImageUrl} />}

        <PageContent direction={languageDirection}>{children}</PageContent>

        {showInstallationWizard && (
          <Box>
            <InstallationWizard
              organizationId={organization.id}
              siteId={site.id}
            />
          </Box>
        )}
      </PageContentWrapper>
    </PageContainer>
  )
}

export default Page
